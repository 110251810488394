import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/angebot/kontaktlinsen/header.jpg"
import BildOben from "../../images/angebot/kontaktlinsen/anim.gif"
import BildUnten from "../../images/angebot/kontaktlinsen/Pentacam_70700_Optiker_201409_03_Herpes_Virus_Screen.jpg"
import SubNavigation from "../../components/SubNavAngebot"
import Termin from "../../components/Termin"

const Kontaktlinsen: React.FC = () => {
  const siteTitle = "Kontaktlinsen - Angebot"
  const greenVer = true
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle} greenVer={greenVer} background="#00aa9b">
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Kontaktlinsen aber richtig"
      />
      <section className="wrapper greyColor content">
        <h1>Kontaktlinsen aber richtig</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildOben} />
              </div>
            </div>
            <p className="imageCaption">
              Kontaktlinse als modisches Accessoire
              <br />
              Mit speziellen Kontaktlinsen lässt sich die Augenfarbe verändern
              und an das Outfit modisch anpassen.
              <br />
              <br />
            </p>
            <div className="imageCollection">
              <div className="imageRow">
                <img src={BildUnten} />
              </div>
            </div>
            <p className="imageCaption">
              Erfassung der Hornhaut-Topographie (Bildquelle: OCULUS).
            </p>
          </div>
          <div className="contentColumn">
            <h2>Die unsichtbare Seh-Alternative</h2>
            <p>
              Die Anpassung von Kontaktlinsen verlangt eine hohe Fachkompetenz,
              denn jedes Auge ist so persönlich wie ein Fingerabdruck und jede
              Kontaktlinse ist so individuell wie das Auge dem sie angepasst
              wird. Dafür gibt es keine Universal-Lösung. Während seines
              Studiums wird der eidg. dipl. Augenoptiker ganz speziell auch für
              die anspruchsvolle Kontaktlinsenanpassung ausgebildet. Mit diesem
              Wissen und einer Vielzahl von Kontaktlinsen-Typen und -Systemen,
              modernsten Materialien sowie optimierten Mess- und Anpasstechniken
              lassen sich mit Kontaktlinsen Fehlsichtigkeiten meist ideal
              korrigieren.
            </p>
            <h2>Vorteile von Kontaktlinsen gegenüber einer Brille</h2>
            <p>
              Beim Tragen von Kontaktlinsen profitieren Sie von einem
              uneingeschränkten Gesichtsfeld sowie mehr Bewegungsfreiheit und
              Sicherheit im Alltag und bei Sport- und Freizeitaktivitäten. Es
              herrscht klare Sicht – kein lästiges Beschlagen.
            </p>
            <h2>Keine Qual der Wahl</h2>
            <p>
              Kontaktlinsen oder doch Brille? Oder beides? Wir beraten Sie
              gerne.
            </p>
            <br />
            <p>
              Bei SCHWARZ Optik erhalten Sie Informationsmaterial für Ihre
              optimale Wahl in Sachen Linsen.
            </p>
            <p>
              Unser Focus als zertifizierte Gesundheitsoptiker liegt bei der
              regelmässigen Vorsorge-Untersuchung Ihrer Augen. Das rechtzeitige
              Erkennen von Sehstörungen und das Ergreifen entsprechender
              Massnahmen können vorbeugen.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Kontaktlinsen
